import React from 'react';
import { withIntlApp } from '@moxy/next-intl';
import { withRouter } from 'next/dist/client/router';
import '../src/assets/styles/main.scss';
import { Provider } from 'react-redux';
import { AppProps } from 'next/app';
import { WithRouterProps } from 'next/dist/client/with-router';
import Script from 'next/script';
import Head from 'next/head';
import { store } from '../src/redux/configureStore';
import { Base } from '../src/layouts/BaseComponent';
import { config } from '../src/config/config';
import { nextAsset } from '../src/components/nextAsset';

/* eslint-disable react/no-danger */
const MyApp: React.FC<AppProps<WithRouterProps>> = ({ Component, pageProps }) => {
  const favicon = nextAsset('logos/favicon-square@2048px.png');
  const promoImg = nextAsset('logos/promo-en@2x.png');
  return (
    <Provider store={store}>
      <Head>
        <link rel="shortcut icon" type="image/png" href={favicon} />
        <meta
          name="viewport"
          key="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
        />
        <script
          dangerouslySetInnerHTML={{
            __html: `window.plausible = window.plausible || function() { (window.plausible.q = window.plausible.q || []).push(arguments) }`
          }}
        />
        <link rel="apple-touch-icon" href={nextAsset('logos/logo-square-rounded@2048px.png')} />

        <meta itemProp="image" content={promoImg} key="image" />

        <meta property="twitter:site" content="@freeyourmusic" key="twitter-site" />
        <meta property="twitter:image" content={promoImg} key="twitter-image" />
        <meta property="twitter:image:width" content="2400" key="twitter-image-width" />
        <meta property="twitter:image:height" content="1200" key="twitter-image-height" />
        <meta property="twitter:card" content="summary_large_image" key="twitter-card" />

        <meta property="og:image" content={promoImg} key="fb-image" />
        <meta property="og:type" content="website" key="fb-type" />
        {config.plausible && (
          <Script
            src={`${config.plausible.url}js/script.manual.js`}
            strategy="afterInteractive"
            data-domain={config.plausible.siteId}
          />
        )}

        <link
          href="https://fonts.googleapis.com/css?family=Permanent+Marker:400&display=swap"
          rel="preload"
          as="style"
          crossOrigin="anonymous"
        />
        <link href="https://fonts.googleapis.com/css?family=Permanent+Marker:400&display=swap" rel="stylesheet" />
        <link
          href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,700&display=swap"
          rel="preload"
          as="style"
          crossOrigin="anonymous"
        />
        <link href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,700&display=swap" rel="stylesheet" />
      </Head>
      <Base Component={Component} pageProps={pageProps} />
    </Provider>
  );
};

const loadLocale = async (locale = 'en') => {
  const module = await import(/* webpackChunkName: "intl-messages" */ `../src/lang/${locale}.json`);
  return module.default;
};

// eslint-disable-next-line import/no-default-export
export default withRouter(withIntlApp(loadLocale)(MyApp));
