export enum UserModelRole {
  Normal,
  Admin
}

export interface JWTUser {
  id: number;
  email: string;
  isAdmin: boolean;
  role: UserModelRole;
  licenseId: number | undefined;
}

export interface AppInfoStateType {
  cookieConsent: boolean;
  userToken: string | null;
  userTokenDecoded: JWTUser | null;
}

export const AppInfoState: AppInfoStateType = {
  cookieConsent: false,
  userToken: null,
  userTokenDecoded: null
};
