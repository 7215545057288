import jwtDecode from 'jwt-decode';
import { JWTUser } from './state';

export function decodeAPITokenJWT(token: string | null): JWTUser | null {
  if (!token) {
    return null;
  }

  try {
    return jwtDecode(token) as JWTUser;
  } catch (error) {
    console.log(error);
    return null;
  }
}
