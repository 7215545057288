import { Config, ConfigEnvironment } from './ConfigType';

export const config: Config = {
  env: ConfigEnvironment.STAGING,
  release: '7f52b219913242d0ec03b4591e24768248b4274e',
  sentryDSN: undefined,
  websiteURL: 'http://rc.customtales.com/',
  apiURLFrontend: '/api_api/',
  plausible: {
    siteId: 'rc.customtales.com',
    url: 'https://plausible.stampapp.io/'
  }
};
