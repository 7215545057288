export type AvailableLanguages = 'en';
export const availableLanguages: AvailableLanguages[] = ['en'];
export function isSupportedLanguage(
  lang: string | undefined | null,
  availableLocales = availableLanguages
): lang is AvailableLanguages {
  if (!lang) {
    return false;
  }
  return (availableLocales as string[]).includes(lang);
}
