export enum ConfigEnvironment {
  LOCAL,
  STAGING,
  BETA,
  PRODUCTION
}

export interface Config {
  env: ConfigEnvironment;
  release: string;
  sentryDSN?: string;
  apiURLFrontend: string;
  websiteURL: string;
  plausible?: {
    siteId: string;
    url: string;
  };
}
