import { configureStore, ThunkDispatch } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';
import logger from 'redux-logger';
import { get } from 'local-storage';
import { rootReducer } from './root/reducer';
import { RootState } from './root/state';
import { appSliceName } from './app/constants';
import { decodeAPITokenJWT } from './app/decodeAPITokenJWT';
import { config } from '../config/config';
import { ConfigEnvironment } from '../config/ConfigType';

const userToken = get<string>('userToken');
const userTokenDecoded = decodeAPITokenJWT(userToken);
const preloadedState = {
  [appSliceName]: {
    ...RootState[appSliceName],
    userToken,
    userTokenDecoded
  }
};

const turnOnReduxLogger = false;
const isProduction = config.env === ConfigEnvironment.PRODUCTION;

export const store = configureStore({
  preloadedState,
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    const defaultMiddleware = getDefaultMiddleware({ serializableCheck: false });
    const middlewareArray = [...defaultMiddleware];
    return isProduction || !turnOnReduxLogger ? middlewareArray : [...middlewareArray, logger];
  },
  devTools: !isProduction
});

export type StoreType = typeof store & {
  dispatch: ThunkDispatch<typeof store['getState'], any, AnyAction>;
};

export type RootStateType = ReturnType<StoreType['getState']>;
