import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { set } from 'local-storage';
import { AppInfoState } from './state';
import { decodeAPITokenJWT } from './decodeAPITokenJWT';

type SetUserTokenPayload = { token: string | null };

const appSlice = createSlice({
  name: 'app',
  initialState: AppInfoState,
  reducers: {
    setUserToken: (state, action: PayloadAction<SetUserTokenPayload>) => {
      // eslint-disable-next-line no-param-reassign
      state.userToken = action.payload.token;
      // eslint-disable-next-line no-param-reassign
      state.userTokenDecoded = decodeAPITokenJWT(action.payload.token);
      set('userToken', action.payload.token);
    },
    logOut: (state) => {
      // eslint-disable-next-line no-param-reassign
      state.userToken = null;
      // eslint-disable-next-line no-param-reassign
      state.userTokenDecoded = null;
      set('userToken', null);
    },
    setCookieConsent: (state, action: PayloadAction<boolean>) => {
      // eslint-disable-next-line no-param-reassign
      state.cookieConsent = action.payload;
    }
  }
});

export const appReducer = appSlice.reducer;
export const { setCookieConsent, setUserToken, logOut } = appSlice.actions;
